(function () {
  const ApoyoRangeControl = (rangeControl) => {
    const min = parseFloat(rangeControl.getAttribute("min") || 0);
    const max = parseFloat(rangeControl.getAttribute("max") || 100);

    const rangeLabel = document.querySelector(
      rangeControl.dataset.rangeDisplay
    );
    let labelMask = undefined;

    const onSlide = (event) => {
      const currentValue = event.target.value;
      displayValue(currentValue);
    };

    const displayValue = (value) => {
      labelMask.unmaskedValue = value;
      rangeLabel.value = labelMask.value;
    };

    const onTyped = () => {
      const currentValue = parseFloat(labelMask.unmaskedValue);
      if (currentValue < min) {
        rangeControl.value = min;
        displayValue(min.toString());
      } else if (currentValue > max) {
        rangeControl.value = max;
        displayValue(max.toString());
      } else {
        rangeControl.value = currentValue;
      }

      if ("createEvent" in document) {
        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        rangeControl.dispatchEvent(evt);
      } else {
        rangeControl.fireEvent("onchange");
      }
    };

    if (rangeLabel) {
      labelMask = IMask(rangeLabel, {
        mask: Number,
        thousandsSeparator: ".",
        scale: 2,
        signed: true,
        padFractionalZeros: true,
        radix: ",",
      });
      rangeLabel.addEventListener("change", onTyped);
    }

    rangeControl.addEventListener("change", onSlide);
    displayValue(rangeControl.value);
  };

  document
    .querySelectorAll(".apoyo-range")
    .forEach((el) => new ApoyoRangeControl(el));
})();
