$(function () {
  jQuery.validator.addMethod(
    "cpf",
    function (value, element) {
      if (this.optional(element)) return true;

      let numbers = [].filter.call(value, (char) => !isNaN(char)).join("");

      if (
        numbers.length != 11 ||
        numbers === "00000000000" ||
        numbers === "11111111111"
      )
        return false;

      const multiple = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

      let dig1 = 0,
        dig2 = 0;
      for (let index = 0; index < numbers.length - 2; index++) {
        const num = numbers[index] - 0;

        dig1 += num * multiple[index + 1];
        dig2 += num * multiple[index];
      }

      dig1 = dig1 % 11;
      if (dig1 !== numbers[9] - 0) return false;
      dig2 = (dig2 + dig1 * 9) % 11;
      if (dig2 !== numbers[10] - 0) return false;

      return true;
    },
    "informe um cpf válido"
  );

  const houseForm = document.getElementById("house-loan-form");
  const carForm = document.getElementById("car-loan-form");

  const makeRequest = (form) => axios.post("/simulador", $(form).serialize());

  if (houseForm) {
    var houseWizard = FormWizard.createWizard(houseForm, {
      submitHandler: function (form, event) {
        event.preventDefault();

        const btn = houseForm.querySelector('[type="submit"]');
        btn.disabled = true;

        makeRequest(form)
          .then((success) => {
            var modal = new bootstrap.Modal(
              document.getElementById("idModalSucess")
            );
            modal.show();

            gtag("event", "conversion", {
              send_to: "AW-955384683/6g_7CMjD1_sBEOuGyMcD",
              event_callback: function () {},
            });
          })
          .catch((error) => {
            var modal = new bootstrap.Modal(
              document.getElementById("idModalError")
            );
            modal.show();
          }).then(function(){
                btn.disabled = false; 
          });
      },
      rules: {
        motivo: { required: true },
        nome: {
          required: true,
          minlength: 3,
          maxlength: 75,
        },
        email: {
          required: true,
          email: true,
        },
        cpf: {
          required: true,
          cpf: true,
        },
        telefone: {
          required: true,
        },
        valorEmprestimo: {
          required: true,
        },
        valorImovel: {
          required: true,
        },
        cep: {
          required: true,
          minlength: 9,
          maxlength: 9,
        },
        profissao: {
          required: true,
          minlength: 3,
          maxlength: 75,
        },
        rendaFamiliar: {
          required: true,
        },
        numeroParcelas: {
          required: true,
        },
      },
      messages: {
        motivo: { required: "informe o motivo do empréstimo" },
        outroMotivo: { required: "informe o motivo do empréstimo" },
        nome: {
          required: "informe seu nome",
          minlength: jQuery.validator.format(
            "seu nome deve conter no mínimo {0} caracteres"
          ),
          maxlength: jQuery.validator.format(
            "seu nome deve conter no máximo {0} caracteres"
          ),
        },
        email: {
          required: "informe seu email",
          email: "email inválido",
        },
        cpf: {
          required: "informe seu cpf",
        },
        telefone: {
          required: "informe seu telefone",
        },
        valorEmprestimo: {
          required: "informe o valor do empréstimo",
        },
        valorImovel: {
          required: "informe o valor do imóvel",
        },
        cep: {
          required: "informe o cep do imóvel",
          minlength: "cep inválido",
          maxlength: "cep inválido",
        },
        profissao: {
          required: "informe sua profissão",
          minlength: jQuery.validator.format(
            "sua profissão deve conter no mínimo {0} caracteres"
          ),
          maxlength: jQuery.validator.format(
            "sua profissão deve conter no máximo {0} caracteres"
          ),
        },
        rendaFamiliar: {
          required: "informe sua renda familiar",
        },
        numeroParcelas: {
          required: "selecione o numero de parcelas",
        },
      },
      errorPlacement: function (error, element) {
        if (element.attr("type") === "radio") {
          error.insertAfter(element.closest(".form-check").parent());
        } else {
          error.insertAfter(element);
        }
      },
    });
  }

  if (carForm) {
    var carWizard = FormWizard.createWizard(carForm, {
      submitHandler: function (form, event) {
        event.preventDefault();

        const btn = houseForm.querySelector('[type="submit"]');
        btn.disabled = true;

        makeRequest(form)
          .then((success) => {
            var modal = new bootstrap.Modal(
              document.getElementById("idModalSucess")
            );
            modal.show();

            gtag("event", "conversion", {
              send_to: "AW-955384683/tywNCMW0zPsBEOuGyMcD",
              event_callback: function () {},
            });
          })
          .catch((error) => {
            var modal = new bootstrap.Modal(
              document.getElementById("idModalError")
            );
            modal.show();
          }).then(function(){
            btn.disabled = false;
          });
      },
      rules: {
        motivo: { required: true },
        nome: {
          required: true,
          minlength: 3,
          maxlength: 75,
        },
        email: {
          required: true,
          email: true,
        },
        cpf: {
          required: true,
          cpf: true,
        },
        telefone: {
          required: true,
        },
        valorEmprestimo: {
          required: true,
        },
        valorVeiculo: {
          required: true,
        },
        profissao: {
          required: true,
          minlength: 3,
          maxlength: 75,
        },
        rendaFamiliar: {
          required: true,
        },
        numeroParcelas: {
          required: true,
        },
      },
      messages: {
        motivo: { required: "informe o motivo do empréstimo" },
        outroMotivo: { required: "informe o motivo do empréstimo" },
        nome: {
          required: "informe seu nome",
          minlength: jQuery.validator.format(
            "seu nome deve conter no mínimo {0} caracteres"
          ),
          maxlength: jQuery.validator.format(
            "seu nome deve conter no máximo {0} caracteres"
          ),
        },
        email: {
          required: "informe seu email",
          email: "email inválido",
        },
        cpf: {
          required: "informe seu cpf",
        },
        telefone: {
          required: "informe seu telefone",
        },
        valorEmprestimo: {
          required: "informe o valor do empréstimo",
        },
        valorVeiculo: {
          required: "informe o valor do imóvel",
        },
        profissao: {
          required: "informe sua profissão",
          minlength: jQuery.validator.format(
            "sua profissão deve conter no mínimo {0} caracteres"
          ),
          maxlength: jQuery.validator.format(
            "sua profissão deve conter no máximo {0} caracteres"
          ),
        },
        rendaFamiliar: {
          required: "informe sua renda familiar",
        },
        numeroParcelas: {
          required: "selecione o numero de parcelas",
        },
      },
      errorPlacement: function (error, element) {
        if (element.attr("type") === "radio") {
          error.insertAfter(element.closest(".form-check").parent());
        } else {
          error.insertAfter(element);
        }
      },
    });
  }
});
