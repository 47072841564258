(function () {
  document.querySelectorAll(".money").forEach((el) =>
    IMask(el, {
      mask: Number,
      thousandsSeparator: ".",
      scale: 2,
      signed: true,
      padFractionalZeros: true,
      radix: ",",
    })
  );

  document.querySelectorAll(".cpf").forEach((el) =>
    IMask(el, {
      mask: "000.000.000-00",
    })
  );

  document.querySelectorAll(".phone").forEach((el) =>
    IMask(el, {
      mask: [{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }],
    })
  );

  document.querySelectorAll(".cep").forEach((el) =>
    IMask(el, {
      mask: "00000-000",
    })
  );
})();
