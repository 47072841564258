window.onload=CapturaParametrosUrl();


function CapturaParametrosUrl() {
    //captura a url da página
    var captura="";
  
    var url = window.location.href;

    
    if (url.search('simulador') > 0 )
   {
    var res = url.split("?");
    if (res[1] !== undefined) {
      captura = res[1].split("=");
  
      hammerType(captura[1]);
      
    } else {
      // selecionando um deafault

  
      hammerType("casa");

    
    }

  } else
      {
        var modal = new bootstrap.Modal(
          document.getElementById("idAvisoCookie")
        );
        modal.show();
      }
  }