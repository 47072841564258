const FormWizard = (function () {
  function Wizard(form, validation) {
    const steps = form.querySelectorAll(".form-wizard-step");
    let currentStep;
    const validator = $(form).validate(validation);

    const onClickToNext = () => {
      if (validator && validator.form()) {
        goToNext();
      }
    };

    const onClickToBack = () => {
      goBack();
    };

    const goToNext = () => {
      steps[currentStep].classList.remove("active");
      steps[currentStep + 1].classList.add("active");
      currentStep = currentStep + 1;
    };

    const goBack = () => {
      steps[currentStep].classList.remove("active");
      steps[currentStep - 1].classList.add("active");
      currentStep = currentStep - 1;
    };

    steps.forEach((step, index) => {
      const nextStepButton = step.querySelector(".btn-next-step");
      const previewStepButton = step.querySelector(".btn-preview-step");

      if (nextStepButton)
        nextStepButton.addEventListener("click", onClickToNext);
      if (previewStepButton)
        previewStepButton.addEventListener("click", onClickToBack);

      if (step.classList.contains("active")) {
        currentStep = index;
      }
    });

    if (!currentStep) {
      steps[0].classList.add("active");
      currentStep = 0;
    }

    return {
      getCurretStep: function () {
        return currentStep;
      },
    };
  }

  return {
    createWizard: function (el, validation) {
      return new Wizard(el, validation);
    },
  };
})();
