(function () {
  document.querySelectorAll(".parcelas").forEach((parcela) => {
    const moneyMask = IMask.createMask({
      mask: Number,
      thousandsSeparator: ".",
      scale: 2,
      signed: true,
      padFractionalZeros: true,
      radix: ",",
    });

    const rangeController = document.querySelector(parcela.dataset.amount);
    const displayLabel = document.querySelector(parcela.dataset.result);
    const taxa = parseFloat(parcela.dataset.tax) / 100;
    let parcelas = 0;

    const calcularPacela = (evt) => {
      const valorEmprestimo = parseFloat(rangeController.value);
      parcelas = parseInt(evt.target.value);

      var value = moneyMask.resolve(
        calcularValor(valorEmprestimo, parcelas, taxa).toString()
      );

      displayLabel.innerText = value;
    };

    const onRangeChanged = () => {
      const valorEmprestimo = parseFloat(rangeController.value);

      var value = moneyMask.resolve(
        calcularValor(valorEmprestimo, parcelas, taxa).toString()
      );

      displayLabel.innerText = value;
    };

    const calcularValor = (montante, parcela, taxa) => {
      return (
        montante *
        ((Math.pow(1 + taxa, parcela) * taxa) /
          (Math.pow(1 + taxa, parcela) - 1))
      );
    };

    parcela.querySelectorAll(".form-check-input").forEach((input) => {
      input.addEventListener("change", calcularPacela);
      if (input.checked) {
        parcelas = parseInt(input.value);
      }
    });

    rangeController.addEventListener("change", onRangeChanged);
    onRangeChanged();
  });
})();
