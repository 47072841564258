const tabsSimulador = (function () {
  const carousel = document.getElementById("loan-types");
  const panels = document.querySelectorAll(".loan-options-form");
  let activePanel = 0;

  const onCarouselChanged = (event) => {
    console.log(event)
    panels[event.from].classList.remove('active');
    panels[event.to].classList.add('active');


    if (panels[event.to].id == "car") {
      newInfoCar();
    } else {
      newInfoImovel();
    }

  };
  carousel.addEventListener("slide.bs.carousel", onCarouselChanged);
  carousel.querySelectorAll(".carousel-item").forEach((item, index) => {
    if (item.classList.contains("active")) {
      activePanel = index;
    }
  });
})();





function hammerType(x) {

  const carousel = document.getElementById("loan-types");
  const carro = document.getElementById("car");
  const casa = document.getElementById("house");

  let activePanel = 0;

  if (x == "casa") {
    carro.classList.remove('active');
  casa.classList.add('active');

  }else{
    casa.classList.remove('active');
    carro.classList.add('active');
}
     
carousel.querySelectorAll(".carousel-item").forEach((item, index) => {
  if (item.classList.contains("active")) {
    activePanel = index;
  }
});


  if (screen.availWidth > 500) {
    select3waysDesk(x);
  }

}


function select3waysDesk(x) {
  var ArrowCarro ;
  var ArrowCasa;

  if (x == "casa")
   {   ArrowCarro = document.getElementById('Arrow-carro'); 
       ArrowCasa = document.getElementById("Arrow-casa");
    } else  {
             ArrowCarro = document.getElementById('Arrow-carro2'); 
             ArrowCasa = document.getElementById("Arrow-casa2");
            }


  
  

  

  document.getElementById(x).style.webkitFilter = "grayscale(0%)";

  if (x == "casa") {

    ArrowCasa.classList.add('arrow-down');
    newInfoImovel();
  }
  if (x == "carro") {

    ArrowCarro.classList.add('arrow-down');
    newInfoCar();
  }


  if (x != "casa") {
    document.getElementById("casa").style.webkitFilter = "grayscale(100%)";
    ArrowCasa.classList.remove('arrow-down');
  }
  if (x != "carro") {
    document.getElementById("carro").style.webkitFilter = "grayscale(100%)";
    ArrowCarro.classList.remove('arrow-down');
  }

 

}



function newInfoCar() {

  document.getElementById("idContCarroInfo").hidden = false;
  document.getElementById("idContImovelInfo").hidden = true;

}

function newInfoImovel() {

  document.getElementById("idContImovelInfo").hidden = false;
  document.getElementById("idContCarroInfo").hidden = true;

}
